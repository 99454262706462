<template>
  <b-sidebar
    id="add-new-manufacturer-sidebar"
    :visible="isAddNewManufacturerSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-add-new-manufacturer-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ formTitle }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit, invalid }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <!-- Manufacturer Name -->
          <validation-provider
            #default="validationContext"
            name="Manufacturer name"
            rules="required"
          >
            <b-form-group
              label="Manufacturer name *"
              label-for="manufacturer-name"
            >
              <vue-autosuggest
                v-model="manufacturer.name"
                :suggestions="nameOptions"
                :input-props="{id:'autosuggest__input', class:'form-control', placeholder:'', readonly: !newManufacturer}"
                :state="getValidationState(validationContext)"
                @input="onInputChange"
                @focus="onFocus"
                @selected="onSelected"
              >
                <template slot-scope="{suggestion}">
                  <span>{{ suggestion.item.name }}</span>
                </template>
              </vue-autosuggest>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <template
            v-if="!newManufacturer"
          >
            <!-- First Name -->
            <validation-provider
              #default="validationContext"
              name="First name"
              rules=""
            >
              <b-form-group
                label="First name"
                label-for="first-name"
              >
                <b-form-input
                  id="first-name"
                  v-model="manufacturer.user.first_name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Last name -->
            <validation-provider
              #default="validationContext"
              name="Last name"
              rules=""
            >
              <b-form-group
                label="Last name"
                label-for="last-name"
              >
                <b-form-input
                  id="last-name"
                  v-model="manufacturer.user.last_name"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Email -->
            <validation-provider
              #default="validationContext"
              name="Email"
              rules="required|email"
            >
              <b-form-group
                label="Email *"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="manufacturer.user.email"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Job Description -->
            <b-form-group
              label="Job description"
              label-for="job-description"
            >
              <b-form-input
                id="job-description"
                v-model="manufacturer.user.job_description"
              />
            </b-form-group>

            <!-- Send reset password email -->
            <validation-provider
              #default="validationContext"
              name="Send invitation"
              rules=""
            >
              <b-form-group
                label="Send invitation"
                label-for="send-invitation"
              >
                <b-form-checkbox
                  id="send-invitation"
                  v-model="sendInvitation"
                  value="true"
                  unchecked-value="false"
                >Send invitation email
                </b-form-checkbox>

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </template>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="invalid"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { VueAutosuggest } from 'vue-autosuggest'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    VueAutosuggest,
  },
  directives: {
    Ripple,
  },
  props: {
    isAddNewManufacturerSidebarActive: {
      type: Boolean,
      required: true,
    },
    formTitle: {
      type: String,
      default: 'Add New Manufacturer',
    },
    manufacturerName: {
      type: String,
      default: '',
    },
    newManufacturer: {
      type: Boolean,
      default: true,
    },
    manufacturersData: {
      type: [Object, Array],
      default: Array,
    },
  },
  data() {
    return {
      nameOptions: [],
      manufacturer: {
        name: '',
        user: {
          first_name: '',
          last_name: '',
          email: '',
          job_description: '',
        },
      },
      sendInvitation: true,
      email,
      required,

      refFormObserver: null,
      getValidationState: null,
    }
  },
  watch: {
    manufacturerName() {
      this.manufacturer.name = this.manufacturerName
    },
  },
  created() {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(() => {})
    this.refFormObserver = refFormObserver
    this.getValidationState = getValidationState
  },
  methods: {
    onFocus() {
      if (!this.newManufacturer) {
        this.nameOptions = []
      }
    },
    onSelected(option) {
      this.manufacturer.name = option.item.name
    },
    onInputChange(text) {
      if (text === '' || text === undefined) {
        this.nameOptions = []
        return
      }
      if (this.manufacturersData) {
        this.nameOptions = [{
          data: this.manufacturersData.filter(item => item.name.toLowerCase()
            .indexOf(text.toLowerCase()) > -1).slice(0, 10),
        }]
      }
    },
    onSubmit() {
      this.$http.post(`/api/v1/manufacturers/${this.newManufacturer ? '?new' : ''}`, this.manufacturer)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Manufacturer added',
              icon: 'UserIcon',
              variant: 'success',
            },
          })
          this.$emit('update:is-add-new-manufacturer-sidebar-active', false)
          if (!this.newManufacturer && this.sendInvitation) {
            this.$http
              .post('/api/v1/users/reset_password/', {
                email: this.manufacturer.user.email,
                invite: true,
              })
          }
          this.$emit('refetch-data')
        })
        .catch(error => {
          const {
            email: emailError,
            message,
          } = error.response.data

          if (emailError) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Email already exists',
                icon: 'EmailIcon',
                variant: 'danger',
              },
            })
          }
          if (message) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: message,
                icon: 'InfoIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';

#add-new-manufacturer-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
